import { Document } from '@contentful/rich-text-types';
import cx from 'classnames';
import React, { ReactElement, useState } from 'react';
import { ISectionFooter } from 'typings/generated/contentful';

import Modal from '../widgets/modal';
import SectionContent from '../widgets/section-content';

interface Props {
  color?: 'blue' | 'green';
  section: ISectionFooter;
}

function Footer({ section, color = 'blue' }: Props): ReactElement {
  const [modalContent, setModalContent] = useState<Document | null>(null);

  return (
    <footer
      className={cx({
        'bg-bg-dark': color === 'blue',
        'bg-bg-dark-green': color === 'green',
      })}
    >
      {/* Footer Section */}
      <SectionContent center={false} padding={false}>
        <div className="flex flex-wrap justify-center py-4 text-lg text-white">
          {section.fields.footerItems?.map((footerItem) => (
            <button
              className="px-4 py-2 outline-none lg:px-8 focus:outline-none"
              key={footerItem.sys.id}
              onClick={() =>
                setModalContent(footerItem.fields.content as Document)
              }
            >
              {footerItem.fields.name}
            </button>
          ))}
          <a
            className="px-4 py-2 outline-none lg:px-8 focus:outline-none"
            href="https://www.reviewhero.io/kaluya"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            Kundenmeinungen
          </a>
        </div>
      </SectionContent>
      <Modal isOpen={!!modalContent} onClose={() => setModalContent(null)}>
        {modalContent}
      </Modal>
    </footer>
  );
}

export default Footer;
