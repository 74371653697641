import { Asset } from 'contentful';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { getFluidImage } from 'src/util/get-fluid-image';

interface Props {
  icon: Asset | undefined;
}

function Favicon({ icon }: Props): ReactElement {
  return (
    <Helmet>
      <link
        href={
          getFluidImage(icon, {
            fm: 'png',
            w: 32,
            h: 32,
          }).src
        }
        id="favicon"
        rel="shortcut icon"
        type="image/png"
      />
    </Helmet>
  );
}

export default Favicon;
