import { Document } from '@contentful/rich-text-types';
import cx from 'classnames';
import React, { ReactElement, useEffect } from 'react';

import ContentfulRichText from './contentful-rich-text';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: Document | null;
}

export default function Modal({
  isOpen,
  onClose,
  children,
}: Props): ReactElement {
  useEffect(() => {
    const body = document.querySelector<HTMLBodyElement>('body');
    if (isOpen && body) {
      body.style.overflow = 'hidden';
    } else if (body) {
      body.style.overflow = 'initial';
    }
  }, [isOpen]);

  return (
    <>
      {/* Backdrop */}
      <div
        className={cx(
          'fixed top-0 bottom-0 left-0 right-0 z-20 flex justify-center items-center px-8 bg-black bg-opacity-50',
          { flex: isOpen, hidden: !isOpen }
        )}
        onClick={onClose}
      >
        {/* Modal Content */}
        {children && (
          <div
            className="w-full h-full max-w-screen-md p-8 overflow-scroll bg-white rounded"
            style={{ maxHeight: '80vh' }}
          >
            <ContentfulRichText document={children} />
          </div>
        )}
      </div>
    </>
  );
}
