import cx from 'classnames';
import React, { ReactElement } from 'react';

interface Props {
  className?: string;
  children: any;
  center?: boolean;
  padding?: boolean;
}

function SectionContent({
  className,
  center = true,
  padding = true,
  children,
}: Props): ReactElement {
  return (
    <div
      className={cx(
        'w-screen max-w-screen-xl mx-auto px-4',
        {
          'flex flex-col items-center justify-center mx-auto': center,
          'py-16': padding,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export default SectionContent;
