import cx from 'classnames';
import React, { ReactElement } from 'react';

interface Props {
  className?: string;
}

function Menu({ className }: Props): ReactElement {
  return (
    <svg
      className={cx('fill-current', className)}
      height="22"
      viewBox="0 0 32 22"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect height="4" rx="2" width="32" />
        <rect height="4" rx="2" width="32" y="9" />
        <rect height="4" rx="2" width="32" y="18" />
      </g>
    </svg>
  );
}

export default Menu;
