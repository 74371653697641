import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import { isEmpty } from 'lodash';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionHeader } from 'typings/generated/contentful';

import Close from '../svg/close';
import Menu from '../svg/menu';
import SectionContent from '../widgets/section-content';

interface Props {
  section: ISectionHeader;
}

function Navbar({ section }: Props): ReactElement {
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const hasMobileNavItems = useMemo(() => !isEmpty(section.fields.navItems), [
    section,
  ]);

  const renderDesktop = useCallback(
    () => (
      <nav className="h-full">
        <ul className="flex items-center justify-center h-full space-x-16 text-lg">
          {section.fields.navItems?.map((navItem) => (
            <li key={navItem.sys.id}>
              <a href={navItem.fields.link}>{navItem.fields.name}</a>
            </li>
          ))}
        </ul>
      </nav>
    ),
    [section]
  );

  const renderHamburgerButton = useCallback(
    () =>
      hasMobileNavItems && (
        <button
          className="relative w-8 h-8 outline-none focus:outline-none"
          onClick={() => setMobileNavbarOpen((v) => !v)}
        >
          <Menu
            className={cx(
              'absolute h-full w-full top-0 left-0 transition-transform transform duration-200',
              {
                'scale-0': mobileNavbarOpen,
                'scale-100': !mobileNavbarOpen,
              }
            )}
          />
          <Close
            className={cx(
              'absolute h-full w-full p-1 top-0 left-0 transition-transform transform duration-200',
              {
                'scale-0': !mobileNavbarOpen,
                'scale-100': mobileNavbarOpen,
              }
            )}
          />
        </button>
      ),
    [mobileNavbarOpen, hasMobileNavItems]
  );

  const renderMobile = useCallback(
    () => (
      <nav
        className={cx('block overflow-hidden lg:hidden mobile-menu', {
          open: mobileNavbarOpen,
        })}
      >
        <ul className="flex flex-col items-center justify-center h-full text-lg">
          {section.fields.navItems?.map((navItem) => (
            <li className="w-full" key={navItem.sys.id}>
              <a
                className="block w-full py-4 text-center"
                href={navItem.fields.link}
                onClick={(e) => {
                  e.preventDefault();
                  setMobileNavbarOpen(false);
                  setTimeout(() => {
                    window.location.href = navItem.fields.link as string;
                  }, 500);
                }}
              >
                {navItem.fields.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    ),
    [section, mobileNavbarOpen]
  );

  return (
    <header className="relative border-b border-bg-light-2 lg:border-0">
      <SectionContent center={false} padding={false}>
        <div
          className={cx('flex flex-row items-center w-full px-2 xl:px-0', {
            'justify-center': !hasMobileNavItems,
            'justify-between': hasMobileNavItems,
          })}
        >
          <a href="/">
            <GatsbyImage
              className="w-24 h-16 contain"
              fluid={getFluidImage(section.fields.logo, { fm: null })}
            />
          </a>
          <div className="hidden lg:block">{renderDesktop()}</div>
          <div className="block lg:hidden">{renderHamburgerButton()}</div>
          <div className="hidden lg:block" />
        </div>
        {renderMobile()}
      </SectionContent>
    </header>
  );
}

export default Navbar;
