import { Asset } from 'contentful';
import { FluidObject } from 'gatsby-image';
import { flow, join, map, merge, toPairs } from 'lodash';

export interface FluidImageOptions {
  q?: number | null;
  w?: number | null;
  h?: number | null;
  fm?: string | null;
  fit?: string | null;
}

const defaultOptions: FluidImageOptions = {
  w: 500,
};

export const getAssetUrl = (
  srcUrl: string | undefined,
  options?: FluidImageOptions
): string => {
  const qs = flow(
    toPairs,
    (pair) => map(pair, (kv) => kv.join('=')),
    (arr) => join(arr, '&')
  )(merge({}, defaultOptions, options));
  return `${srcUrl}?${qs}`;
};

const GIF_WIDTH = 250;

export const getFluidImage = (
  asset: Asset | undefined,
  options: FluidImageOptions = {},
  aspectRatio = 1.2
): FluidObject => {
  // Downsize gifs for smaller filesize
  const isGif = asset?.fields.file?.url.endsWith('gif');
  const downsizedOptions = options || {};
  if (isGif) {
    Object.assign(downsizedOptions, {
      w: GIF_WIDTH,
    });
  }

  let fm;
  if (options.fm !== null) {
    fm = 'webp';
  }

  return {
    src: getAssetUrl(asset?.fields.file?.url, downsizedOptions),
    srcSet: getAssetUrl(asset?.fields.file?.url, downsizedOptions),
    srcSetWebp: getAssetUrl(asset?.fields.file?.url, {
      ...downsizedOptions,
      fm,
    }),
    aspectRatio,
    sizes: '',
  };
};
