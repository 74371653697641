import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISeoInformation } from 'typings/generated/contentful';

interface Props {
  seo: ISeoInformation | undefined;
  canonical?: string;
}

function SEO({ seo, canonical }: Props): ReactElement | null {
  if (!seo) {
    return null;
  }

  const { title, description, image: imageAsset } = seo.fields;
  const image = getFluidImage(imageAsset, {
    w: 1200,
    h: 630,
    fit: 'pad',
    fm: 'png',
  }).src;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'de',
      }}
      link={[
        {
          rel: 'canonical',
          href: canonical,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ]}
      title={title}
    />
  );
}

export default SEO;
